<template>
  <div v-if="user">
    Test
  </div>
</template>

<script>
import Vue from "vue";
export default {
  components:{

  },
  data(){
    return {
      user:null,
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);

  },
  methods:{

  }
}
</script>
<style lang="scss">

</style>
